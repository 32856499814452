<template>
  <div class="unit-convert">
    <el-button class="convert-btn" @click="addFn">+单位转换</el-button>
    <div class="list" v-for="(item, k) in value" :key="k">
      <div class="custom-row">
        <div class="form-item-label">支持单据类型</div>
        <div class="form-item-content">
          <el-select v-model="item.orderType" placeholder="请选择" style="wdth: 100%">
            <el-option
              v-for="item in options"
              :key="item.orderType"
              :label="item.orderTypeName"
              :value="item.orderType"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="custom-row">
        <div class="form-item-label">单位转换关系</div>
        <div class="form-item-content">
          <el-row :gutter="10">
            <el-col :span="4">
              <el-input v-model="item.numerator" placeholder="数量" />
            </el-col>
            <el-col :span="5">
              <el-select v-model="item.unitCode" @change="(e)=>getCode(k,e)" placeholder="请选择">
                <el-option
                  v-for="item in company"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="1">
              <span>=</span>
            </el-col>
            <el-col :span="4">
              <el-input v-model="item.ratio" placeholder="数量" />
            </el-col>
            <el-col :span="5">
              <el-input v-model="item.kaUnitCode" placeholder="商超单位" />
            </el-col>
            <el-col :span="4">
              <el-button @click="delFn(k)">删除</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from '../../../../../../utils/request';

export default {
  props: {
    value: Array,
    bsDirectSystemId: String,
  },
  components: {},
  data() {
    return {
      options: [],
      company: [],
      unitName: '',
    };
  },
  watch: {
    value(value) {
      console.log(value);
    },
    bsDirectSystemId(value) {
      this.selecList(value);
    },

  },
  created() {
    this.getMdmdictDataSelectOptions();
    // this.selecList(bsDirectSystemId);
  },
  methods: {
    selecList(id) {
      request.post('/kms/kmsAdmin/tenantry/direct/orderType/list', { directId: id }, { headers: { functionCode: 'kms_on_product_table' } }).then((res) => {
        if (res.success) {
          console.log(res.result);
          this.options = res.result;
        }
      });
    },
    async getMdmdictDataSelectOptions() {
      const res = await request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'product_sale_unit' });
      if (res.success) {
        console.log(res.result);
        this.company = res.result.map((a) => ({ label: a.dictValue, value: a.dictCode }));
      }
      return [];
    },
    getCode(k, e) {
      console.log(k, e);
      const list = JSON.parse(JSON.stringify(this.value));
      list[k].unitName = this.company.find((item) => item.value === e).label;
      this.$emit('input', list);
      // this.unitName = this.company.find((item) => item.value === e).dictValue;
    },
    addFn() {
      const list = JSON.parse(JSON.stringify(this.value));
      list.push({
        orderType: '',
        unitCode: '',
        ratio: '',
        kaUnitCode: '',
        unitName: '',
      });

      this.$emit('input', list);
    },
    delFn(k) {
      let list = JSON.parse(JSON.stringify(this.value));
      list = list.filter((v, j) => j !== k);
      this.$emit('input', list);
    },
  },
};
</script>
<style lang="less" scoped>
.unit-convert {
  .convert-btn {
    margin-left: 28px;
  }

  .custom-row {
    display: flex;
    margin-top: 18px;

    .form-item-label {
      width: 125px;
      padding: 0 12px 0 0;
      text-align: right;
      flex-shrink: 0;
    }

    .form-item-content {
      flex: 1;
    }
  }
}
</style>
