<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';
import unitConversion from './components/unitConversion';

formCreate.component('unitConversion', unitConversion);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'kms_on_product_form',
      productSelect: [],
    };
  },
  async created() {
    // this.rule = await this.getFormRule('kms_on_product_form');
    // this.getProduct();
  },
  methods: {
    // async beforeGetFormRule() {
    //   await request.post('/mdm/mdmProductController/productSelectList', { enableStatus: '009' }).then((res) => {
    //     const newArr = [];
    //     res.result.forEach((e) => {
    //       newArr.push({
    //         label: e.productCode + e.productName,
    //         value: e.productCode,
    //       });
    //     });
    //     this.productSelect = newArr;
    //   });
    // },

    // beforeSetRule(rule) {
    //   const newRule = rule;
    //   newRule.map((v, i) => {
    //     const item = v;
    //     if (item.field === 'productName') {
    //       item.options = this.productSelect;
    //       newRule[i] = item;
    //       console.log(item);
    //     }
    //     return item;
    //   });
    //   return newRule;
    // },

    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'remarks') {
        item.props = {
          ...item.props,
          type: 'textarea',
        };
      }
      if (item.field === 'sellPartyCode') {
        item.restful = '/mdm/mdmCustomerMsgController/findCustomerSelectList';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'customerName', value: 'customerCode' };
        item.refresh = true;
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'customerName',
        };
        // item.on.optionCallback = (options, value) => console.log(options, value);
      }
      if (item.field === 'productCode') {
        item.restful = '/mdm/mdmProductController/productSelectList';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'productCodeName', value: 'productCode' };
        item.refresh = true;
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'productCodeName',
        };
      }
      if (item.field === 'bsDirectSystemId') {
        item.restful = '/kms/kmstenantrydirectcustomerorg/list';
        item.headers = { functionCode: 'direct_marketing_system_list' };
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'bsDirectSystemName', value: 'bsDirectSystemId' };
        item.refresh = true;
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'bsDirectSystemName',
        };
        // item.on.optionCallback = (options, value) => console.log(options, value);
      }
      // if (item.field === 'orderUnitId') {
      //   item.restful = '/mdm/mdmShelfProductController/findProductUnit';
      //   item.restfulParams = {
      //     enableStatus: '009',
      //   };
      //   item.optionsKey = { label: 'orderUnit', value: 'orderUnitId' };
      //   item.restfulParamsGetValue = { proDetailsNumber: 'proDetailsNumber' };
      //   item.refresh = true;
      // }
      if (item.field === 'storeProductCode') {
        item.validate = [
          ...item.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      } else if (item.field === 'unitReqVos') {
        item.value = [];
      }

      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      // this.disabled(true, 'proDetailsNumber');
      // this.hiddenFields(true, ['directSystemName', 'orderUnit']);
      const productCode = this.getRule('productCode');
      const productName = this.getRule('productName');
      const bsDirectSystemId = this.getRule('bsDirectSystemId');
      const unitReqVos = this.getRule('unitReqVos');
      const directId = this.getRule('directId');
      const orderUnitId = this.getRule('orderUnitId');
      const sellPartyCode = this.getRule('sellPartyCode');
      sellPartyCode.on.change = (e) => {
        const selectSellPartyCode = sellPartyCode.options.find((item) => item.customerCode === e);
        console.log(selectSellPartyCode);
        this.setValue({
          sellPartyName: selectSellPartyCode.customerName,
        });
      };
      productCode.on.change = (e) => {
        const selectPro = productCode.options.find((item) => item.productCode === e);
        this.setValue({
          productName: selectPro.productName,
        });
      };
      bsDirectSystemId.on.change = (e) => {
        // console.log(e);
        const selectDirectSystemId = bsDirectSystemId.options.find((item) => item.bsDirectSystemId === e);
        // console.log(selectDirectSystemId);
        this.setValue({
          bsDirectSystemName: selectDirectSystemId.bsDirectSystemName,
          bsDirectSystemCode: selectDirectSystemId.bsDirectSystemCode,

        });
        // this.updateRules({
        //   unitReqVos: {
        //     props: {
        //       bsDirectSystemId: selectDirectSystemId.directId,
        //     },
        //   },
        // });
        // console.log(selectDirectSystemId);
        unitReqVos.props.bsDirectSystemId = selectDirectSystemId.directId;
        this.setValue({ directId: selectDirectSystemId.directId });
        // console.log(unitReqVos.props);
      };
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.post('/kms/tenantryDirectProduct/query', {
          id: this.formConfig.row.id,
          directSystemId: this.formConfig.row.directSystemId,
          directSystemName: this.formConfig.row.directSystemName,
        }).then((res) => {
          if (res.success) {
            console.log(res);
            this.updateRules({
              unitReqVos: {
                props: {
                  value: res.result.unitRespVos,
                },
              },
            });
            unitReqVos.props.bsDirectSystemId = res.result.directId;
            this.setValue(res.result);
          }
        });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/kms/tenantryDirectProduct/save';
        const params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/kms/tenantryDirectProduct/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
