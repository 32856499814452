var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-convert" },
    [
      _c(
        "el-button",
        { staticClass: "convert-btn", on: { click: _vm.addFn } },
        [_vm._v("+单位转换")]
      ),
      _vm._l(_vm.value, function (item, k) {
        return _c("div", { key: k, staticClass: "list" }, [
          _c("div", { staticClass: "custom-row" }, [
            _c("div", { staticClass: "form-item-label" }, [
              _vm._v("支持单据类型"),
            ]),
            _c(
              "div",
              { staticClass: "form-item-content" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { wdth: "100%" },
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: item.orderType,
                      callback: function ($$v) {
                        _vm.$set(item, "orderType", $$v)
                      },
                      expression: "item.orderType",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.orderType,
                      attrs: {
                        label: item.orderTypeName,
                        value: item.orderType,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "custom-row" }, [
            _c("div", { staticClass: "form-item-label" }, [
              _vm._v("单位转换关系"),
            ]),
            _c(
              "div",
              { staticClass: "form-item-content" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "数量" },
                          model: {
                            value: item.numerator,
                            callback: function ($$v) {
                              _vm.$set(item, "numerator", $$v)
                            },
                            expression: "item.numerator",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            on: {
                              change: function (e) {
                                return _vm.getCode(k, e)
                              },
                            },
                            model: {
                              value: item.unitCode,
                              callback: function ($$v) {
                                _vm.$set(item, "unitCode", $$v)
                              },
                              expression: "item.unitCode",
                            },
                          },
                          _vm._l(_vm.company, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 1 } }, [
                      _c("span", [_vm._v("=")]),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "数量" },
                          model: {
                            value: item.ratio,
                            callback: function ($$v) {
                              _vm.$set(item, "ratio", $$v)
                            },
                            expression: "item.ratio",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "商超单位" },
                          model: {
                            value: item.kaUnitCode,
                            callback: function ($$v) {
                              _vm.$set(item, "kaUnitCode", $$v)
                            },
                            expression: "item.kaUnitCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.delFn(k)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }